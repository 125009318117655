html,
body {
  margin: 0;
}

#root {
  /*height: 100%;*/
}

.App {
  text-align: left;
}

.jwplayer.jw-flag-aspect-mode {
  height: auto !important;
  max-height: 800px !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  font-weight: bold;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
